@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.popup {
  background-color: rgba(000, 000, 000, 0.6);
}
.add {
  bottom: 65px !important;
  transition: all 0.3s;
}
.transition {
  transition: all 0.3s;
}